.FixedComponent {
    position: fixed;
    bottom: 20px;
    padding: 5px;
    height: 50px;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background */
    border-radius: 5px;
    filter: invert(1); /* Invert the color of the icon */
}

.FixedComponent.invert {
    filter: invert(1);
    background-color: black;
    color: white;
  }

.FixedText {
    color: "#fff";
    font-family: "Lexend";
    justify-self: center;
}